<template>
    <div v-show="!loading" class="login-page">
        <section class="login-block">
            <div class="login-left">
                <div class="login-header">
                    <div>Login As</div>
                </div>

                <template v-if="canAdminEyeInWireless()">
                    <div class="text-center">
                        <img class="img-fluid" :src="getPublicIcon('virtual-reality')" style="width: 128px; margin: 10px auto;"/>
                    </div>
                    <div class="form-help mt-2 f-10" v-tr>This feature is only available to administrator. Using it, you can connect to any account without typing the password.|Cette fonctionnalité est uniquement disponible pour les administrateurs. En l'utilisant, vous pouvez vous connecter à n'importe quel compte sans entrer de mot de passe.</div>

                    <b-form-group :label="['Username', 'Nom d\'utilisateur'] | tr">
                        <vue-typeahead-bootstrap inputClass="form-search" :placeholder="'Search a user|Chercher un utilisateur'|tr" :show-all-results="true"
                                                 :data="searchResults" v-model="loginAsUsername" :minMatchingChars="2" :serializer="serializeSearchResult">
                        </vue-typeahead-bootstrap>
                    </b-form-group>

                    <b-btn @click="loginAs" variant="default" class="login-default login-btn" v-tr>Sign In|Se connecter</b-btn>
                    <b-btn class="cancel-button mt-2" @click="$router.back()" v-tr>Cancel|Annuler</b-btn>
                </template>
                <div v-else v-tr>
                    You don't have permission to use this feature.|Vous n'avez pas la permission d'utiliser cette fonctionalité.
                </div>

                <div class="text-center mt-3">
                    <p class="contact-us mt-3 mb-3" v-tr>Contact us|Contactez-nous</p>
                    <a class="login-link link-basic f-10" href="tel:+15146915115">514.395.9327</a><br>
                    <a class="login-link link-basic f-10" href="mailto:info@eyeinmedia.com">info@eyeinmedia.com</a>
                </div>

                <div class="login-footer">Eye-In Média 2024</div>
            </div>
        </section>
    </div>
</template>

<script>
import Network from "../../vue-components/helpers/Network";
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import PermissionHelper from "../../vue-components/helpers/PermissionHelper.js";

export default {
    name: `login-as`,
    components: {
        'vue-typeahead-bootstrap': VueTypeaheadBootstrap
    },
    data() {
        return {
            loading: false,
            loginAsUsername: ``,
            searchResults: [],
            redirectURL: null
        }
    },
    activated() {
        const searchParams = new URLSearchParams(window.location.search)
        let redirectUrl = searchParams.get(`redirect_url`);

        if (!redirectUrl) {
            redirectUrl = localStorage.getItem(`redirect_url`);
        }

        if (!redirectUrl) {
            redirectUrl = window.env.VUE_APP_URLS__EYEINTELLIGENCE
        }

        this.redirectURL = new URL(redirectUrl);
    },
    methods: {
        canAdminEyeInWireless() {
            return PermissionHelper.can(`admin`, null, `Eye-In Wireless`);
        },
        loginAs() {
            let username = this.loginAsUsername.split(`(`)[0].trim();

            if (username.length < 3)
                return;

            Network.post(`/users/login-as`, {username: username})
                .then(resp => {
                    this.loginAsUsername = ``;
                    this.postLoginProcess(resp.data);
                })
                .catch(() => {
                    this.$swal({
                        icon: `error`,
                        title: this.tr(`Could not login|Connexion échouée`),
                        text: this.tr(`Could not login|Connexion échouée`)
                    });
                });
        },
        postLoginProcess(user) {
            try {
                this.redirectURL.searchParams.append(`token`, user.token);
                window.location.href = this.redirectURL.toString();
            } catch (e) {
                console.error(e);
            }
        },
        async startSearchRequests(searchTerm) {
            if (searchTerm.length < 1)
                return;

            Network.get(`/users/search/${this.loginAsUsername}`)
                .then(resp => {
                    this.searchResults = resp.data;
                    console.log(this.searchResults);
                });
        },
        serializeSearchResult(user) {
            return `${user.username} (${user.dealerid})`;
        }
    },
    watch: {
        loginAsUsername: debounce(function(searchTerm) {
            this.startSearchRequests(searchTerm)
        }, 500)
    }
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not be triggered.
 * The function will be called after it stops being called for N milliseconds.
 * If 'immediate' is passed, trigger the function on the leading edge, instead of the trailing.
 * @param func
 * @param wait
 * @param immediate
 * @returns {function(): *}
 */
function debounce(func, wait, immediate) {
    let timeout, result;
    return function() {
        let context = this;
        let args = arguments;
        let later = function() {
            timeout = null;
            if (!immediate)
                result = func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow)
            result = func.apply(context, args);
        return result;
    };
}
</script>

<style lang="scss" scoped>
.login-page {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    background: url("https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/login_bg.jpg") no-repeat center;
    background-size: cover;
    min-height: 100%;
    height: auto;
    padding-top: 65px;
    padding-bottom: 20px;

    .login-block {
        position: relative;
        display: inline-block;
        padding: 0;
        width: 380px;
        height: 800px;
        max-width: calc(100% - 20px);
        box-sizing: border-box;
        margin-top: 0;
        vertical-align: top;
        border-radius: 10px;
        box-shadow: none;
        font-size: 12pt;
        min-height: 550px;
        color: white;
        background: linear-gradient(8deg, #2b2838 0%, #415368 100%);
        overflow: hidden;

        .login-left {
            position: absolute;
            display: inline-block;
            padding: 40px 40px;
            top: 0;
            left: 0;
            width: 380px;
            height: 100%;
            vertical-align: top;

            .login-header {
                font-size: 28px;
                font-weight: lighter;
                color: white;
                font-variant: petite-caps;
                letter-spacing: 2px;
            }

            .login-header-subsection {
                font-weight: 100;
                margin: 30px 0;
                text-align: left;
                letter-spacing: 2px;

                .link-basic {
                    opacity: 0.5;
                    transition: 150ms;
                    color: inherit;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline !important;
                    }
                }
            }

            label {
                color: #56b9d7;
                border-radius: 5px;
                font-weight: 400;
                font-size: 10pt;
                line-height: 20px;
                text-align: left;
                letter-spacing: 2px;
                margin-bottom: 2px;
                margin-left: 10px;
                width: 100%;
            }

            input {
                padding: 5px 10px;
                font-weight: 500;
                font-size: 11pt;
                border: none;
                border-bottom: 2px solid #56b9d7;
                color: white;
                width: 100%;
                background-color: transparent !important;
                border-radius: 0;
                margin-bottom: 20px;
            }

            button[type="submit"] {
                cursor: pointer;
                user-select: none;
                border-radius: 50px;
                color: #ffffff;
                text-transform: initial;
                font-weight: 400;
                letter-spacing: 2px;
                font-size: 12pt;
                background: linear-gradient(to bottom, #52b9cc 0%, #2c70b6 100%);
                box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.05);
                padding: 12px 20px;
                border: 0;
                display: inline-block;
                width: 100%;
                transition: 150ms;

                &:hover {
                    transform: scale(1.05);
                    background: linear-gradient(to bottom, #57ccdf 0%, #2c70b6 100%);
                }

                &:active {
                    transform: scale(0.95);
                    background: linear-gradient(to bottom, #51a3b6 0%, #285d9c 100%);
                }
            }

            .button-forgot-password {
                margin-bottom: 20px;
                color: #56b9d7;
                letter-spacing: 1px;
            }

            .contact-us {
                font-size: 10pt;
                letter-spacing: 1px;
                text-transform: uppercase;
                opacity: 0.8;
            }

            .login-divider {
                margin: 10px;
                font-size: 9pt;
                letter-spacing: 1px;
                opacity: 0.7;
            }

            .link-basic {
                margin-bottom: 20px;
                color: #56b9d7;
                letter-spacing: 1px;
                cursor: pointer;

                &:hover {
                    text-decoration: underline !important;
                }
            }

            .login-footer {
                position: absolute;
                left: 0;
                bottom: 50px;
                margin-top: 50px;
                font-size: 9pt;
                letter-spacing: 2px;
                width: 100%;
            }
        }
    }

    .login-right {
        position: absolute;
        width: calc(100% - 390px);
        display: inline-block;
        height: 100%;
        top: 0;
        right: 0;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
    }
}

.carousel {
    height: 100%;

    &::v-deep .carousel-inner {
        height: 100%;

        .carousel-item {
            height: 100%;

            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &::v-deep .carousel-indicators li {
        border-radius: 50%;
        width: 6px;
        height: 6px;
        margin-right: 6px;
        margin-left: 6px;
    }
}


.login-btn {
    cursor: pointer;
    user-select: none;
    border-radius: 50px;
    color: #ffffff;
    text-transform: initial;
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 12pt;
    background: linear-gradient(to bottom, #52b9cc 0%, #2c70b6 100%);
    box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.05);
    padding: 12px 20px;
    border: 0;
    display: inline-block;
    width: 100%;
    transition: 150ms;
    position: relative;

    &.login-icon-btn {
        display: inline-block;
        width: auto;
        border-radius: 3px;
        margin: 0 5px;

        .fab,
        .fas {
            font-size: 20px !important;
        }
    }

    &:hover {
        transform: scale(1.05);
        background: linear-gradient(to bottom, #57ccdf 0%, #2c70b6 100%);
    }

    &:active {
        transform: scale(0.95);
        background: linear-gradient(to bottom, #51a3b6 0%, #285d9c 100%);
    }
}

.cancel-button {
    cursor: pointer;
    user-select: none;
    border-radius: 50px;
    color: #ffffff;
    text-transform: initial;
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 12pt;
    background: linear-gradient(to bottom, #d6d9de 0%, #adaeb6 100%);
    box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.05);
    padding: 12px 20px;
    border: 0;
    display: inline-block;
    width: 100%;
    transition: 150ms;

    &:hover {
        transform: scale(1.05);
        background: linear-gradient(to bottom, #dfdcdc 0%, #b6b2b3 100%);
    }

    &:active {
        transform: scale(0.95);
        background: linear-gradient(to bottom, #b4aeb6 0%, #9c959c 100%);
    }
}

.back {
    position: absolute;
    top: 20px;
    left: 20px;
}

@media(max-width: 576px) {
    .login-page {
        padding: 0;

        .login-block {
            max-width: 100%;

            .login-left {
                padding: 15px;
                width: 100%;
            }

            .login-right {
                display: none;
            }
        }
    }
}
</style>
