<template>
    <app-page fullscreen class="d-flex justify-content-center align-items-center" :data-cy="locale">
        <div class="login-page">
            <section class="login-block">
                <div class="login-left">
                    <div class="login-header" v-if="appName">
                        <small v-tr>Welcome to|Bienvenue sur</small>
                        <div>{{ appName }}</div>
                    </div>

                    <div v-if="logoUrl">
                        <b-img :src="logoUrl" style="margin-top: 20px; max-height: 100px;"></b-img>
                    </div>

                    <template v-if="loading">
                        <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
                    </template>
                    <template v-else>
                        <div class="login-header-subsection">
                            <span v-tr>Sign In|Se connecter</span> <a @click="changeLanguage()" class="link-basic float-right" data-cy="language-button" v-tr>Français|English</a>
                        </div>

                        <form class="login">
                            <div class="form-group" v-if="passwordEnabled">
                                <label for="login-username" v-tr>Your Username / Email|Nom d'utilisateur / Courriel</label>
                                <input id="login-username" class="form-control" name="username" required v-model="username" type="text" placeholder=""/>
                            </div>

                            <template v-if="passwordEnabled">
                                <LoginPassword :username="username" @user="postLoginProcess"></LoginPassword>
                            </template>

                            <div class="text-right">
                                <div class="other-sign-in text-center mt-3">
                                    <div class="login-divider" v-show="passwordEnabled && (googleEnabled || microsoftEnabled)" v-tr>OR|OU</div>
                                    <LoginGoogle v-if="googleEnabled" :small="microsoftEnabled" @user="postLoginProcess"></LoginGoogle>
                                    <LoginMicrosoft :small="googleEnabled" @user="postLoginProcess"></LoginMicrosoft>
                                </div>
                            </div>
                        </form>
                    </template>

                    <div class="text-center mt-3">
                        <p class="contact-us mt-3 mb-3" v-tr>Contact us|Contactez-nous</p>
                        <!--                    <a class="login-link link-basic f-10" href="tel:+15146915115">514.395.9327</a><br>-->
                        <a class="login-link link-basic f-10" style="text-decoration: underline" href="mailto:support@eyeinmedia.com">support@eyeinmedia.com</a>

                        <div class="login-footer">Eye-In Média</div>
                    </div>
                </div>
                <div class="login-right" v-if="!popup">
                    <b-carousel
                        :interval="4000"
                        controls
                        indicators>

                        <b-carousel-slide :img-src="`https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/5_do_you_know_eyeintelligence_${language}.jpg`"></b-carousel-slide>
                        <b-carousel-slide :img-src="`https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/2_survey_${language}.jpg`"></b-carousel-slide>
                        <b-carousel-slide :img-src="`https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/3_newsletter_${language}.jpg`"></b-carousel-slide>
                        <b-carousel-slide :img-src="`https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/4_data_single_application_${language}.jpg`"></b-carousel-slide>
                        <b-carousel-slide :img-src="`https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/6_contact_${language}.jpg`"></b-carousel-slide>
                        <b-carousel-slide :img-src="`https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/login_azure.jpg`"></b-carousel-slide>
                        <b-carousel-slide :img-src="`https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/1_welcome_${language}.jpg`"></b-carousel-slide>
                    </b-carousel>
                </div>
            </section>
        </div>
    </app-page>
</template>

<script>
import Network from "../../vue-components/helpers/Network";
import {changeLanguage, getLanguage} from "../../vue-components/helpers/i18n";
import moment from "moment-mini";
import LoginMicrosoft from "@/components/LoginMicrosoft.vue";
import LoginPassword from "@/components/LoginPassword.vue";
import LoginGoogle from "@/components/LoginGoogle.vue";

export default {
    name: `login`,
    components: {LoginGoogle, LoginPassword, LoginMicrosoft},
    data() {
        return {
            loading: true,
            appName: `Eye-Intelligence`,
            username: ``,
            logoUrl: null,
            passwordEnabled: true,
            googleEnabled: true,
            microsoftEnabled: true,
            eyeinEmployeeOnly: false,
            redirectURL: null,
            popup: false,
            locale: null
        }
    },
    async activated() {
        const searchParams = new URLSearchParams(window.location.search);
        this.locale = getLanguage();

        let redirectUrl = searchParams.get(`redirect_url`);

        if (searchParams.get(`popup`)) {
            this.popup = true;
        }

        if (!redirectUrl) {
            redirectUrl = sessionStorage.getItem(`redirect_url`);
        }

        if (!redirectUrl) {
            redirectUrl = localStorage.getItem(`redirect_url`);
        }

        if (!redirectUrl) {
            redirectUrl = window.env.VUE_APP_URLS__EYEINTELLIGENCE;
        }

        const redirectDomain = new URL(redirectUrl).host;

        try {
            const resp = await Network.get(`/eyein/login-params`, {domain: redirectDomain});

            this.appName = resp.data.app_name;
            this.logoUrl = resp.data.logo_url;
            this.googleEnabled = resp.data.google_enabled;
            this.microsoftEnabled = resp.data.microsoft_enabled;
            this.passwordEnabled = resp.data.password_enabled;
            this.eyeinEmployeeOnly = resp.data.eyein_employee_only;
        } catch (e) {
            if (!this.isLive() && this.isLocalDomain(redirectDomain)) {
                this.appName = `Local app test`;
            } else {
                redirectUrl = window.env.VUE_APP_URLS__EYEINTELLIGENCE;

                if (e.response.status === 404) {
                    localStorage.removeItem(`token`);
                    Network.removeToken();
                    this.$swal(this.tr(`Error|Erreur`), this.tr(`The website requesting login is not recognized. Please make sure someone is not trying to steal your credentials.|Le site ayant fait une requête d'autentification n'est pas reconnu. Vérifiez que personne n'essaie de voler vos identifiants.`), `error`);
                    return
                }
            }
        }

        sessionStorage.setItem(`redirect_url`, redirectUrl);
        localStorage.setItem(`redirect_url`, redirectUrl);
        this.redirectURL = new URL(redirectUrl);

        this.$forceUpdate();

        if (searchParams.get(`logout`)) {
            localStorage.removeItem(`token`);
            await Network.removeToken();
            this.loading = false;
            return;
        }

        const token = localStorage.getItem(`token`);
        if (!token) {
            this.loading = false;
            return;
        }

        try {
            await Network.setToken(token);

            const resp = await Network.get(`/users/current-user`);

            const user = resp.data;
            if (!this.eyeinEmployeeOnly || user.user_level === `admin`) {
                this.postLoginProcess(user);
            } else {
                localStorage.removeItem(`token`);
                await Network.removeToken();
                this.loading = false;
            }
        } catch {
            localStorage.removeItem(`token`);
            await Network.removeToken();
            this.loading = false;
        }
    },
    methods: {
        changeLanguage() {
            changeLanguage();
            this.$forceUpdate();
            this.locale = getLanguage();
        },
        async postLoginProcess(user) {
            try {
                await Network.setToken(user.token);
                localStorage.setItem(`token`, user.token);
                const tokenExpiration = moment.unix(JSON.parse(atob(user.token.split(`.`)[1])).exp);
                localStorage.setItem(`token_expiration`, tokenExpiration.toISOString(true));

                if (this.popup) {
                    window.opener.postMessage(user.token, this.redirectURL.origin);
                } else {
                    this.redirectURL.searchParams.set(`language`, getLanguage());
                    this.redirectURL.searchParams.set(`token`, user.token);
                    window.location.href = this.redirectURL.toString();
                }
            } catch (e) {
                console.error(e);
            }
        },
        isLocalDomain(domain) {
            return /^localhost:\d+$/.test(domain) || /^192\.168\.\d{1,3}\.\d{1,3}:\d+$/.test(domain) || /^10\.\d{1,3}\.\d{1,3}\.\d{1,3}:\d+$/.test(domain)
        }
    }
}
</script>

<style lang="scss" scoped>
.login-page {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    background: url("https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/login_bg.jpg") no-repeat center;
    background-size: cover;
    min-height: 100%;
    height: auto;
    padding-top: 65px;
    padding-bottom: 20px;

    .login-block {
        position: relative;
        display: inline-block;
        padding: 0;
        width: 1280px;
        height: 800px;
        max-width: calc(100% - 20px);
        box-sizing: border-box;
        margin-top: 0;
        vertical-align: top;
        border-radius: 10px;
        box-shadow: none;
        font-size: 12pt;
        min-height: 550px;
        color: white;
        background: linear-gradient(8deg, #2b2838 0%, #415368 100%);
        overflow: hidden;

        .login-left {
            position: absolute;
            display: inline-block;
            padding: 30px 40px;
            top: 0;
            left: 0;
            width: 380px;
            height: 100%;
            vertical-align: top;

            @media(min-width: 1100px) {
                max-height: calc(100vh - 80px);
                overflow: hidden auto;
            }

            .login-header {
                font-size: 28px;
                font-weight: lighter;
                color: white;
                font-variant: petite-caps;
                letter-spacing: 2px;
            }

            .login-header-subsection {
                font-weight: 100;
                margin: 20px 0;
                text-align: left;
                letter-spacing: 2px;

                .link-basic {
                    opacity: 0.5;
                    transition: 150ms;
                    color: inherit;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline !important;
                    }
                }
            }

            label {
                color: #56b9d7;
                border-radius: 5px;
                font-weight: 400;
                font-size: 10pt;
                line-height: 20px;
                text-align: left;
                letter-spacing: 2px;
                margin-bottom: 2px;
                margin-left: 10px;
                width: 100%;
            }

            input {
                padding: 5px 10px;
                font-weight: 500;
                font-size: 11pt;
                border: none;
                border-bottom: 2px solid #56b9d7;
                color: white;
                width: 100%;
                background-color: transparent !important;
                border-radius: 0;
                margin-bottom: 20px;
            }

            .login-btn {
                cursor: pointer;
                user-select: none;
                border-radius: 3px;
                color: #ffffff;
                text-transform: initial;
                font-weight: 400;
                letter-spacing: 2px;
                font-size: 12pt;
                background: linear-gradient(to bottom, #52b9cc 0%, #2c70b6 100%);
                box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.05);
                padding: 12px 20px;
                border: 0;
                display: inline-block;
                width: 100%;
                transition: 150ms;
                position: relative;

                &.login-icon-btn {
                    display: inline-block;
                    width: auto;
                    border-radius: 3px;
                    margin: 0 5px;

                    .fab,
                    .fas {
                        font-size: 20px !important;
                    }
                }

                &:hover {
                    transform: scale(1.05);
                    background: linear-gradient(to bottom, #57ccdf 0%, #2c70b6 100%);
                }

                &:active {
                    transform: scale(0.95);
                    background: linear-gradient(to bottom, #51a3b6 0%, #285d9c 100%);
                }
            }

            .contact-us {
                font-size: 10pt;
                letter-spacing: 1px;
                text-transform: uppercase;
                opacity: 0.8;
            }

            .login-divider {
                margin: 10px;
                font-size: 9pt;
                letter-spacing: 1px;
                opacity: 0.7;
            }

            .link-basic {
                margin-bottom: 20px;
                color: #56b9d7;
                letter-spacing: 1px;
                cursor: pointer;

                &:hover {
                    text-decoration: underline !important;
                }
            }

            .login-footer {
                margin-top: 20px;
                font-size: 9pt;
                letter-spacing: 2px;
            }
        }

        .login-right {
            position: absolute;
            width: calc(100% - 380px);
            display: inline-block;
            height: 100%;
            top: 0;
            right: 0;
            border-top-right-radius: inherit;
            border-bottom-right-radius: inherit;
        }
    }

    .carousel {
        height: 100%;

        &::v-deep .carousel-inner {
            height: 100%;

            .carousel-item {
                height: 100%;

                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        &::v-deep .carousel-indicators li {
            border-radius: 50%;
            width: 6px;
            height: 6px;
            margin-right: 6px;
            margin-left: 6px;
        }
    }
}

@media(max-height: 800px) {
    .login-page {
        padding-top: 0;
    }
}

@media(max-width: 1024px) {
    .login-page {
        padding: 0;

        .login-block {
            max-width: 100%;

            .login-left {
                padding: 15px;
                width: 100%;
            }

            .login-right {
                display: none;
            }
        }
    }
}
</style>
